<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <AllKitchenmonitorDailyReportComponent />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <KitchenmonitorsOverviewComponent />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <SalesChartNumberOfProcessedTotalOrders />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AllKitchenmonitorDailyReportComponent from "@/components/settings/kitchenmonitor/dashboard/AllKitchenmonitorDailyReportComponent";
import KitchenmonitorsOverviewComponent from "@/components/settings/kitchenmonitor/dashboard/KitchenmonitorsOverviewComponent.vue";
import SalesChartNumberOfProcessedTotalOrders from "@/components/settings/kitchenmonitor/dashboard/SalesChartNumberOfProcessedTotalOrders.vue";

export default {
  components: {
    AllKitchenmonitorDailyReportComponent,
    KitchenmonitorsOverviewComponent,
    SalesChartNumberOfProcessedTotalOrders,
  },
};
</script>
