var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"main-card mb-3 card",attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"card-header-tab card-header",class:[this.$vuetify.theme.dark ? 'dark-bg' : '']},[_c('div',{staticClass:"card-header-title card-title font-size-lg text-capitalize font-weight-normal",class:[this.$vuetify.theme.dark ? 'text-white' : '']},[_c('font-awesome-icon',{staticClass:"pr-2 daily",attrs:{"icon":['fal', 'calendar-day'],"size":"2x"}}),_vm._v(" "+_vm._s(_vm.$t("settings.lang_dailyOverviewDashboard"))+" ")],1)]),_c('div',{staticClass:"grid-menu grid-menu-3col"},[_c('div',{staticClass:"no-gutters row"},[_c('div',{staticClass:"col-sm-6 col-md-6",class:[
          _vm.$store.getters['permissions/checkModule'](2)
            ? 'col-lg-3 col-xl-3'
            : 'col-lg-4 col-xl-4',
        ]},[_c('div',{staticClass:"widget-chart"},[_c('div',{staticClass:"icon-wrapper rounded-circle"},[_c('div',{staticClass:"icon-wrapper-bg bg-primary"}),_c('font-awesome-icon',{staticClass:"mx-auto",attrs:{"icon":['fas', 'clock'],"size":"2x"}})],1),_c('div',{staticClass:"widget-numbers"},[_vm._v(_vm._s(_vm.data.timePerOrder)+" (m:s)")]),_c('div',{staticClass:"widget-subheading"},[_vm._v(" "+_vm._s(_vm.$t("settings.lang_timeToFinishAnOrder"))+" ")])])]),_c('div',{staticClass:"col-sm-6 col-md-6",class:[
          _vm.$store.getters['permissions/checkModule'](2)
            ? 'col-lg-3 col-xl-3'
            : 'col-lg-4 col-xl-4',
        ]},[_c('div',{staticClass:"widget-chart"},[_c('div',{staticClass:"icon-wrapper rounded-circle"},[_c('div',{staticClass:"icon-wrapper-bg bg-alternate"}),_c('font-awesome-icon',{staticClass:"mx-auto",attrs:{"icon":['fas', 'coins'],"size":"2x"}})],1),_c('div',{staticClass:"widget-numbers"},[_vm._v(" "+_vm._s(_vm.data.finishedPerHour.toFixed(2))+" x ")]),_c('div',{staticClass:"widget-subheading"},[_vm._v(" "+_vm._s(_vm.$t("settings.lang_finishedOrdersPerHour"))+" ")])])]),_c('div',{staticClass:"col-sm-6 col-md-6",class:[
          _vm.$store.getters['permissions/checkModule'](2)
            ? 'col-lg-3 col-xl-3'
            : 'col-lg-4 col-xl-4',
        ]},[_c('div',{staticClass:"widget-chart"},[_c('div',{staticClass:"icon-wrapper rounded-circle"},[_c('div',{staticClass:"icon-wrapper-bg bg-warning"}),_c('font-awesome-icon',{staticClass:"mx-auto",staticStyle:{"font-size":"22px"},attrs:{"icon":['fas', 'tv-alt'],"size":"2x"}})],1),_c('div',{staticClass:"widget-numbers"},[_vm._v(_vm._s(_vm.data.totalKioskOrder || 0)+" x")]),_c('div',{staticClass:"widget-subheading"},[_vm._v(" "+_vm._s(_vm.$t("settings.lang_ordersFromKiosks"))+" ")])])]),_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-3 col-xl-3"},[_c('div',{staticClass:"widget-chart"},[_c('div',{staticClass:"icon-wrapper rounded-circle"},[_c('div',{staticClass:"icon-wrapper-bg bg-success"}),_c('font-awesome-icon',{staticClass:"mx-auto",attrs:{"icon":['fas', 'utensils'],"size":"2x"}})],1),_c('div',{staticClass:"widget-numbers"},[_vm._v(_vm._s(_vm.data.totalPosOrder || 0)+" x")]),_c('div',{staticClass:"widget-subheading"},[_vm._v(" "+_vm._s(_vm.$t("settings.lang_ordersFromPos"))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }